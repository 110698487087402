import {
  post,
  get
} from '../serve/http'
export default {
  searchMdmOrg(params) {
    return post(`${process.env.VUE_APP_WDCX_URL}/addressCenterSortManager/addrmdmorg/searchMdmOrg`, params)
  },
  getDistrict(id){
    if(process.env.NODE_ENV == "development"){
      return post(`/gw/api/base/citys/bypid?id=${id}`)
    }else{
      return post(`${process.env.VUE_APP_YTO_GW}/api/base/citys/bypid?id=${id}`)
    }
  },
  // platformService(AppId,params){
  //   return post(`/gis/YTO_GPSITF_WEB/PlatformService?AppId=${AppId}`,params)
  // },
}