<template>
  <div class="address-content" id="ceshibox">
    <div class="input-select-content">
      <!-- <div class="close" @click="close">
        <i class="el-icon-close"></i>
      </div> -->
      <div class="select-father">
        <ul class="select-nav">
          <li :class="{ active: statusCH === 1 }" @click="statusCH = 1">
            热门
          </li>
          <li :class="{ active: statusCH === 2 }" @click="statusCH = 2">
            省份
          </li>
          <li :class="{ active: statusCH === 3 }" @click="statusCH = 3">
            城市
          </li>
          <li
            class="no-border-right"
            :class="{ active: statusCH === 4 }"
            @click="statusCH = 4"
          >
            区县
          </li>
        </ul>
        <div class="select-content">
          <ul class="select-content-item" v-if="statusCH === 1">
            <li
              v-for="item in hot"
              :key="item.code"
              @click="handleProv(item)"
              :class="{ active: address.provinceCode == item.code }"
              :title="item.name"
            >
              {{ item.name }}
            </li>
          </ul>
          <ul class="select-content-item" v-else-if="statusCH === 2">
            <li
              v-for="item in province"
              :key="item.code"
              @click="handleProv(item)"
              :class="{ active: address.provinceCode == item.code }"
              :title="item.name"
            >
              {{ item.name }}
            </li>
          </ul>
          <ul class="select-content-item" v-else-if="statusCH === 3">
            <li
              v-for="item in city"
              :key="item.id"
              @click="handleInnerCity(item)"
              :class="{ active: address.cityCode == item.id }"
              :title="item.name"
            >
              {{ item.name }}
            </li>
          </ul>
          <ul class="select-content-item" v-else-if="statusCH === 4">
            <li
              v-for="item in county"
              :key="item.id"
              @click="handleInnerCounty(item)"
              :class="{ active: address.countyCode == item.id }"
              :title="item.name"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getNetpoint from "@/api/getNetpoint";
import chinaDropdown from "./china_dropdown.vue";
import area from "./area";
export default {
  name: "country_dropdown",
  components: { chinaDropdown },
  props: {
    clearAddrFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statusCH: 1, // 热门 /省份 /市区   大陆中的二级选择
      address: {
        isCountry: false,
        provinceName: "",
        provinceCode: "",
        cityName: "",
        cityCode: "",
        countyName: "",
        countyCode: "",
      },
      province: area.province,
      hot: [],
      nation: "",
      children: [],
      city: [],
      county: [],
    };
  },
  mounted() {
    this.hot = area.hot;
    // window.addEventListener(
    //   "click",
    //   (e) => {
    //     if (e.target.className == "address-content") {
    //       this.$emit("closeDialog");
    //     }
    //   },
    //   true
    // );
  },
  methods: {
    submitCity() {
      this.$emit("getData", this.address);
    },
    handleProv(obj) {
      this.address.provinceName = obj.name;
      this.address.provinceCode = obj.code;
      this.address.cityName = "";
      this.address.cityCode = "";
      this.address.countyName = "";
      this.address.countyCode = "";
      /**
       * 无法请求
       */
      // this.city=area.city;
      // this.statusCH = 3;
      getNetpoint.getDistrict(obj.code).then((res) => {
        if (res.code === "success") {
          if (obj.isCharteredCities) {
            this.city = [];
            this.county = res.data;
            this.statusCH = 4;
          } else {
            this.city = res.data;
            this.statusCH = 3;
          }
        }
      });
    },
    // 大陆内地城市
    handleInnerCity(obj) {
      this.address.isBigLand = true;
      this.address.isGAT = false;

      this.handleCity(obj);
    },
    handleCity(obj) {
      this.address.isCountry = false;

      this.address.cityName = obj.name;
      this.address.cityCode = obj.id;
      this.address.countyName = "";
      this.address.countyCode = "";
      getNetpoint.getDistrict(obj.id).then((res) => {
        if (res.code === "success") {
          this.statusCH = 4;
          this.county = res.data;
        }
      });
    },
    handleInnerCounty(obj) {
      this.address.countyName = obj.name;
      this.address.countyCode = obj.id;
      this.$emit("completeChoiceCounty", this.address);
    },
    initAddress() {
      this.address = {
        provinceName: "",
        provinceCode: "",
        cityName: "",
        cityCode: "",
        countyName: "",
        countyCode: "",
      };
      this.statusCH = 2;
    },
    close(){
      this.$emit("closeDialog")
    }
  },
  watch: {
    clearAddrFlag(newVal) {
      if (newVal) {
        this.initAddress();
        this.$emit("initClearAddrFlag");
      }
    },
  },
};
</script>
<style lang='less' scoped>
.address-content {
    // width: 100vw;
    // height: 100vh;
    background: transparent;
    position: relative;
    z-index: 1000;
    top: 0px;
}
.input-select-content {
  position: absolute;
  background: #fff;
  width: 700px;
  overflow: hidden;
  padding: 40px 15px 15px;
  border-radius: 6px;
  line-height: 30px;
  font-size: 14px;
  color: #898989;
  border: 1px solid #d5d5d6;
  margin-top: -20px;
  .close{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    .el-icon-close{
      font-size: 20px;
    }
  }
  .select-father{
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #d5d5d6;
    padding-bottom: 10px;
  }
  .select-nav {
    width: 100%;
    height: 30px;
    overflow: hidden;
    display: flex;
    padding: 0;
    margin: 0;
    // border-bottom: 1px solid #eee;
    li {
      line-height: 30px;
      float: left;
      flex-grow: 1;
      box-sizing: border-box;
      color: #898989;
      // width: 33.33% !important;
      margin: 0 !important;
      height: 100%;
      text-align: center;
      list-style: none;
      border-bottom: 1px solid #d5d5d6;
      border-right: 1px solid #d5d5d6;
      cursor: pointer;
    }
    li.no-border-right {
      border-right: 0;
    }

    li.active {
      border-bottom: 0;
    }
  }

  .select-content {
    // padding: 15px;
    max-height: 70vh;
    .long-country {
      overflow-x: hidden;
    }
    .select-content-item {
      max-height: 70vh;
      display: flex;
      flex-wrap: wrap;
      li {
        margin: 5px 5px;
        padding: 0 2px;
        height: 30px;
        width: 22%;
        color: #898989;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        border-radius: 4px;
        // border: 1px solid rgba(71, 7, 108, 0.2);
        box-sizing: border-box;
        font-size: 14px;
        &:active,
        &:hover {
          color: #fff;
          background-color: #47086c;
          // border-bottom: 2px #47076C solid;
        }
      }
      .active {
        color: #fff;
        background-color: #47086c;
        // border-bottom: 2px #47076C solid;
      }
    }
  }
  .hot {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 5px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #d5d5d6;
    span {
      float: left;
      height: 30px;
      width: 33%;
      border-radius: 6px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      &:active,
      &:hover {
        // color: #fff;
        // background-color: #47086c;
        border-bottom: 2px #47076c solid;
      }
    }
  }
  .search-inp {
    display: block;
    margin: 3px auto;
    height: 2em !important;
    width: 89%;
    border-radius: 10px;
    text-align: center;
  }
  .search {
    position: relative;
    z-index: 10;
    background: #fff;
    width: 99%;
    .search-list {
      width: 100%;
      min-height: 1em;
      position: absolute;
      background: #fff;
      border: 1px solid #d5d5d6;
      border-radius: 10px;
      box-shadow: 2px 2px 5px 2px #d5d5d6;
      li {
        float: left;
        height: 30px;
        width: 25%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        // border-radius: 6px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        &:active,
        &:hover {
          // color: #fff;
          // background-color: #47086c;
          border-bottom: 2px #47076c solid;
        }
      }
    }
  }
}
</style>