/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'; // 引入axios
import {
  Message
} from 'element-ui';
let whiteList = [], //重复请求后，取消未完成的请求的白名单
  unrepeatStr = []; //不发送重新请求的白名单
// axios.defaults.baseURL = '/api';
// axios.defaults.baseURL = baseUrl;
// 请求超时时间
axios.defaults.timeout = 10000;
//axios请求超时,设置重新请求
axios.defaults.retry = 2;
axios.defaults.retryDelay = 5000;
// post请求头
// axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
let pending = [],
  cancelToken = axios.CancelToken,
  removePending; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
removePending = (config) => {
  for (let p in pending) {
    if (pending[p].u === config.url + '&' + config.method) { //当当前请求在数组中存在时执行函数体
      pending[p].f(); //执行取消操作
      pending.splice(p, 1); //把这条记录从数组中移除
    }
  }
}
// 响应拦截器
axios.interceptors.request.use((config) => {

  if (whiteList.indexOf(config.url) < 0) {
    removePending(config); //在一个ajax发送前执行一下取消操作
    config.cancelToken = new cancelToken((c) => {
      // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
      pending.push({
        u: config.url + '&' + config.method,
        f: c
      });
    });
  }
  return config;
}, (error) => {
  return Promise.reject('请稍后刷新重试');
});
axios.interceptors.response.use(
  response => {
    // response = JSON.parse(jsEvent.desDecode(response.data));
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      Message.error(response.message)
      // return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况    
  error => {
    if (error.response == undefined) {
      // Message.error("网络异常,请稍后再试！")
    } else {
      console.log("error",error);
      // Message.error(error.response.data.message.toString());
    }
    return Promise.reject(error.response);
  }
);

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
        params: params
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        // throw new Error(err);
        reject(err);
      });
  })
}
