<template>
  <div style="position: relative">
    <div class="search-box">
      <el-form :inline="true" :model="form" class="form-inline">
        <el-form-item>
          <div @click="showDialogFn" class="address-box">
            {{ form.addr || "请选择省市区" }}
            <i
              v-if="form.addr"
              style="cursor: pointer"
              class="el-icon-circle-close el-input__icon clear-addr"
              slot="suffix"
              @click="handleIconClick"
            >
            </i>
          </div>
          <!-- <el-input
            v-model="form.addr"
            clearable
            placeholder="请选择省市区"
            class="addr"
            readonly=""
            @focus="showDialogFn"
            @clear="clearAddr"
          >
          <i
            v-if="form.addr"
            style="cursor: pointer;"
            class="el-icon-circle-close el-input__icon"
            slot="suffix"
            @click="handleIconClick">
          </i>
          </el-input> -->
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="form.detailedAddress"
            clearable
            class="detailed-address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="query('true')"
            :class="{ disabledBtn: disabledQuery }"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <div class="express-delivery">
        <el-button type="primary"
          ><a
            href="https://www.yto.net.cn/ytoExpress/subCreatePages/create"
            target="_blank"
            ><img
              src="../assets/icon_qujian.png"
              style="width: 14px; height: 14px; margin-right: 6px"
            />免费预约上门取件</a
          ></el-button
        >
      </div>
    </div>
    <Area
      :dialogVisible="dialogVisible"
      :clearAddrFlag="clearAddrFlag"
      v-show="dialogVisible"
      @completeChoiceCounty="completeChoiceCounty"
      @initClearAddrFlag="clearAddrFlag = false"
    ></Area>
    <baidu-map
      :center="center"
      :zoom="zoom"
      :scroll-wheel-zoom="true"
      @ready="handler"
      class="bm-view"
    >
      <!-- <bml-marker-clusterer :averageCenter="true"> -->
      <bm-marker
        v-for="(item, index) in markers"
        :key="index"
        :position="item.location"
        :icon="{
          url: `${publicPath}searchMarkerPurple.png`,
          size: { width: 31, height: 43 },
        }"
        @click="infoWindowOpen(item)"
        @mouseover="mouseoverMarker"
      >
        <bm-info-window
          :width="600"
          :height="320"
          :show="item.showHint"
          @close="infoWindowClose(item)"
          @open="infoWindowOpen(item)"
        >
          <div>
            <div class="title">
              {{ item.orgName }}
            </div>
            <div class="addr">
              <img src="../assets/icon_weizhi.png" alt="" class="addr-img" />
              <span>地址：{{ item.orgAddress }}</span>
            </div>
            <div class="contact">
              <img src="../assets/icon_dianhua.png" alt="" class="addr-img" />
              <span>联系方式：{{ item.hotline || item.takePhone }}</span>
              <span
                v-if="item.hotline || item.takePhone"
                class="mobel forbidClick"
                :id="'auto' + index"
                @click="mobileError(item, index)"
                >举报电话异常</span
              >
            </div>
            <div class="contact">
              <img src="../assets/icon_psfanwei.png" alt="" class="addr-img" />
              <span>派送范围：{{ item.normalDispatchIn }}</span>
            </div>
            <div class="contact">
              <img
                src="../assets/icon_tspsfanwei.png"
                alt=""
                class="addr-img"
              />
              <span>特殊派送范围：{{ item.specialDispatchIn }}</span>
            </div>
            <div class="contact">
              <img src="../assets/icon_kztquyu.png" alt="" class="addr-img" />
              <span>可自提区域：{{ item.customersDispatchIn }}</span>
            </div>
            <div class="contact">
              <img src="../assets/icon_fpsfanwei.png" alt="" class="addr-img" />
              <span>非派送范围：{{ item.dispatchOut }}</span>
            </div>
            <div class="contact">
              <img src="../assets/icon_tsfwxinxi.png" alt="" class="addr-img" />
              <span class="content-padding"
                >特殊服务信息展示：{{ item.specialService }}</span
              >
            </div>
          </div>
          <!-- <button @click="item.showHint = false">Clear</button> -->
        </bm-info-window>
      </bm-marker>
      <!-- </bml-marker-clusterer> -->
    </baidu-map>
  </div>
</template>

<script>
// @ is an alias to /src
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import Area from "@/components/country_dropdown";
import getNetpoint from "@/api/getNetpoint";
import { BmlMarkerClusterer } from "vue-baidu-map";

export default {
  name: "Home",
  data() {
    let vm = this;
    return {
      indexMap: -1,
      publicPath: process.env.BASE_URL,
      // hackReset: true,
      map: null,
      BMap: null,
      center: { lng: 116.399, lat: 39.91 },
      zoom: 12,
      // show: false,
      active: false,
      markers: [],
      form: {
        addr: "",
        detailedAddress: "",
      },
      address: {},
      queryData: "",
      dialogVisible: false,
      clearAddrFlag: false,
    };
  },
  computed: {
    disabledQuery() {
      return !(
        this.queryData ||
        this.address.provinceName ||
        this.form.detailedAddress
      );
    },
  },
  components: {
    BaiduMap,
    Area,
    BmlMarkerClusterer,
  },
  created() {
    this.queryData = this.$route.query?.address;
    console.log("---", this.$route.query);
    if (!this.queryData) {
      return;
    }
    const address = this.queryData.split(",");
    if (address.length == 4) {
      this.address.provinceName = address[0];

      if (address[1]) {
        this.address.cityName = address[1];
        this.form.addr = `${address[0]}${address[1]}${address[2]}`;
      } else {
        this.form.addr = `${address[0]}${address[2]}`;
      }
      this.address.countyName = address[2];
      this.indexMap = address[3];
    } else {
      this.form.addr = this.queryData.replaceAll(",", "");
      this.address.provinceName = address[0];
      if (address.length === 2) {
        this.address.countyName = address[1];
      } else if (address.length === 3) {
        this.address.cityName = address[1];
        this.address.countyName = address[2];
      }
    }
    this.query();
  },
  mounted() {
    window.addEventListener(
      "click",
      (e) => {
        let father = document.getElementById("ceshibox");
        if (
          !father.contains(e.target) &&
          !(
            e.target.className == "el-input__inner" &&
            e.target.offsetParent.className.indexOf("addr") != -1
          )
        ) {
          this.closeDialog();
        }
      },
      true
    );
    window.addEventListener("message", () => {
      console.log("1111");
      this.dialogVisible && this.closeDialog();
    });
  },
  methods: {
    mobileError(item, index) {
      let { province, city, county, address } = this.getQueryParams();
      this.$set(item, "province", province);
      this.$set(item, "county", county);
      this.$set(item, "address", address);
      this.$set(item, "city", city);
      window.parent.postMessage({ item, index }, "*");
    },
    showDialogFn(e) {
      if (e.target.className.indexOf("clear-addr") > -1) {
        return;
      }
      !this.dialogVisible && (this.dialogVisible = true);
    },
    handleIconClick() {
      this.address = {};
      this.form.addr = "";
      this.queryData = "";
    },
    handler({ BMap, map }) {
      // console.log(BMap, map);
      this.map = map;
      this.BMap = BMap;
      // map.addEventListener("zoomend", function(e){
      //   let zoom= map.getZoom(); // 获取缩放级别
      //   console.log("zoom",zoom);
      //   console.log("getCenter",map.getCenter());
      // // 其余操作
      // });
      if (!this.queryData) {
        let geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition((r) => {
          let point = new BMap.Point(r.longitude, r.latitude);
          map.centerAndZoom(point, this.zoom);
        });
      }
    },
    infoWindowClose(item) {
      item.showHint = false;
    },
    infoWindowOpen(item) {
      item.showHint = true;
    },
    mouseoverMarker(e) {
      // e.target.setIcon(new this.BMap.Icon(`${this.publicPath}cm0.png`,new this.BMap.Size(33,32)));
    },
    // handleClick() {
    //   this.show = false;
    // },
    // getDataByLinkAddress() {
    //   if (this.$route.query?.address) {
    //     this.form.addr = this.$route.query.address;
    //     this.query(this.$route.query.address);
    //   }
    // },
    query(flag) {
      let vm = this;
      if (flag) {
        vm.indexMap = -1;
      }
      if (this.disabledQuery) {
        this.$message({
          message: "请选择查询地址！",
          type: "warning",
        });
        return;
      }
      getNetpoint
        .searchMdmOrg(this.getQueryParams())
        .then(function ({ success, data, message }) {
          if (success) {
            let hasCenter = false,
              markers = [];
            // vm.hackReset = false;
            vm.markers = [];
            //无网点
            if (!data.length) {
              vm.$message({
                message: message,
                type: "warning",
                duration: 2000,
                offset: 100,
              });
              vm.setCenterAddr();
              return;
            }
            //有网点
            data.map((item) => {
              item.showHint = false;
              if (item.location) {
                item.normalDispatchIn = item.normalDispatchIn
                  ? item.normalDispatchIn.split(/<br>/g).join("")
                  : "";
                item.specialDispatchIn = item.specialDispatchIn
                  ? item.specialDispatchIn.split(/<br>/g).join("")
                  : "";
                item.customersDispatchIn = item.customersDispatchIn
                  ? item.customersDispatchIn.split(/<br>/g).join("")
                  : "";
                item.dispatchOut = item.dispatchOut
                  ? item.dispatchOut.split(/<br>/g).join("")
                  : "";
                item.specialService = item.specialService
                  ? item.specialService.split(/<br>/g).join("")
                  : "";
                markers.push(item);
                if (!hasCenter) {
                  vm.center = item.location;
                  hasCenter = true;
                }
              }
            });
            vm.$nextTick(() => {
              //所有数据无经纬度
              !hasCenter && vm.setCenterAddr();

              vm.markers = markers;
              if (vm.indexMap > -1) {
                //增加判断如果是从会员页面回跳到地图页面需要带着查询信息以及坐标来打开地图
                vm.markers.forEach((item, index) => {
                  if (index == vm.indexMap) {
                    vm.infoWindowOpen(item);
                    setTimeout(() => {
                      document.querySelector("#auto" + index).click();
                      console.log(
                        "9999",
                        document.querySelector("#auto" + index)
                      );
                    }, 3000);
                  }
                });
              }
            });
          }
        })
        .catch(() => {
          vm.setCenterAddr();
        });
    },
    getQueryParams() {
      let {
        provinceName: province,
        cityName: city,
        countyName: county,
      } = this.address;
      return {
        province: province,
        city: city || "",
        county: county,
        address: this.form.detailedAddress,
      };
    },
    getCenterAddr(queryAddress) {
      if (queryAddress) {
        return queryAddress;
      } else {
        return `${this.address.provinceName}${this.address.cityName}${this.address.countyName}${this.form.detailedAddress}`;
      }
    },
    setCenterAddr() {
      if (this.map) {
        this.map.centerAndZoom(this.getCenterAddr(this.queryData), this.zoom);
      } else {
        let st = setInterval(() => {
          if (this.map) {
            window.clearInterval(st);
            this.map.centerAndZoom(
              this.getCenterAddr(this.queryData),
              this.zoom
            );
          }
        }, 150);
      }
    },
    completeChoiceCounty(data) {
      this.closeDialog();
      this.address = data;
      this.form.addr = `${this.address.provinceName}${this.address.cityName}${this.address.countyName}`;
      this.queryData = "";
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    clearAddr() {
      this.clearAddrFlag = true;
    },
  },
};
</script>
<style lang="less">
.address-box {
  position: relative;
  height: 38px;
  line-height: 38px;
  vertical-align: middle;
  padding: 0 5px;
  border-radius: 8px;
  color: #888;
  padding-left: 10px;
  width: 300px;
  display: inline-block;
  border: 1px solid #47076c;
  cursor: text;
  i {
    position: absolute;
    cursor: pointer;
    right: 12px;
  }
}
.form-inline {
  text-align: left;
}
.search-box {
  height: 100px;
  // padding: 0 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .express-delivery {
    text-align: end;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
}
.bm-view {
  width: 100%;
  height: calc(100vh - 100px);
  .BMap_bottom {
    display: none;
  }
  .BMap_pop {
    > div,
    > img:nth-child(10) {
      display: none;
      overflow: unset;
    }
    > div:nth-child(9) {
      display: block;
      overflow: unset;
      // width: 340px !important;
    }
    .BMap_top {
      display: none;
    }
    .BMap_center {
      background: transparent;
      border: none;
      position: sticky !important;
      height: 100%;
    }
  }
  .BMap_bubble_content {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
    > div:nth-child(2) {
      & > div {
        & > span {
          width: 93px !important;
        }
      }
    }
    .info-window {
      // padding-right: 8px;
      width: 100%;
      max-height: 280px;
      overflow: auto;
      .el-divider {
        background: #ccccccbf;
      }
      .address {
        color: #fff;
      }
    }
    //自定义滚动条样式
    .info-window::-webkit-scrollbar {
      width: 6px;
      height: 1px;
    }
    .info-window::-webkit-scrollbar-thumb {
      border-radius: 6px;
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #535353;
    }
    .info-window::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      background: #ededed;
    }
  }
  .BMap_shadow img {
    height: 0px;
    width: 0px;
  }
  .BMap_bubble_content {
    overflow-y: auto !important;
    height: 300px !important;
  }
  ::-webkit-scrollbar {
    width: 8px;

    height: 8px;

    background-color: #aaa;
  }

  /*定义滚动条轨道

  内阴影+圆角*/

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #aaa;

    border-radius: 10px;

    background-color: #aaa;
  }

  /*定义滑块

  内阴影+圆角*/

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;

    -webkit-box-shadow: inset 0 0 6px #ccc;

    background-color: #ccc;
  }
  .title {
    font-size: 18px;
    color: #47076c;
    line-height: 25px;
  }
  .addr,
  .contact {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #47076c;
    line-height: 20px;
    text-align: left;
    margin-top: 10px;
    img {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    span {
      display: block;
    }
  }
  .content-padding {
    padding-bottom: 20px;
  }
  .mobel {
    display: inline-block;
    width: 122px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    color: #fff;
    background: #47086c;
    font-size: 16px;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 22px;
    margin-right: 22px;
  }
  .forbidClick {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }
}
.el-input__inner:focus {
  border-color: #47076c !important;
}
.el-button--primary {
  background-color: #47076c;
  border-color: #47076c;
  &:focus,
  &:hover {
    background: #47076c;
    border-color: #47076c;
  }
}
.el-button {
  padding: 11px 20px;
}
.el-input__inner {
  height: 30px;
  line-height: 30px;
}
.el-form-item {
  margin-bottom: 0;
}
.el-input__inner {
  height: 38px;
  line-height: 38px;
  vertical-align: middle;
  padding: 0 5px;
  border-radius: 8px;
  color: #888;
  padding-left: 10px;
}
.addr {
  .el-input__inner {
    width: 240px;
  }
}
.addr-img {
  width: 20px !important;
  height: 24px !important;
}
.detailed-address {
  .el-input__inner {
    width: 300px;
  }
}
.disabledBtn {
  background: #898989;
  border: 1px solid #898989;
  &:hover {
    background: #898989;
    border: 1px solid #898989;
  }
}
</style>
