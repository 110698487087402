<template>
  <div class="input-select-content">
    <ul class="select-nav">
      <li :class="{'active': status === 1}" @click="status=1">热门</li>
      <li :class="{'active': status === 2}" @click="status=2">省份</li>
      <li class="no-border-right" :class="{'active': status === 3}" @click="status=3">市区</li>
    </ul>
    <div class="select-content">
      <ul class="select-content-item" v-show="status === 1">
        <li v-for="(item,key) in hot" :key="item" @click="handleProv(key,item)" :class="{'active': address.provinceCode == item}">{{key}}</li>
      </ul>
      <ul class="select-content-item" v-show="status === 2">
        <li v-for="(item,key) in province" :key="item" @click="handleProv(key,item)" :class="{'active': address.provinceCode == item}" :title="item.name">{{key}}</li>
      </ul>
      <ul class="select-content-item" v-show="status === 3">
        <li v-for="item in city" :key="item.id" @click="handleCity(item)" :class="{'active': address.cityCode == item.id}" :title="item.name">{{item.name}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
// import baseDataApi from '~/api/baseData'
import area from './area'
export default {
  name: 'china_dropdown',
  props: ['apple'],
  data() {
    return {
      status: 1,
      address: {
        provinceName: '',
        provinceCode: '',
        cityName: '',
        cityCode: ''
      },
      province:
        { "北京": 3110101, "上海": 3310101, "天津": 3120101, "重庆": 3500101, "江苏": 2320102, "浙江": 2330102, "安徽": 2340102, "河北": 2130102, "河南": 2410102, "湖北": 2420102, "湖南": 2430482, "江西": 2360203, "广东": 2440103, "黑龙江": 2230102, "吉林": 2220102, "辽宁": 2210102, "山东": 2370102, "山西": 2140121, "陕西": 2610102, "四川": 2510104, "青海": 2630102, "甘肃": 2620421, "贵州": 2520102, "福建": 2350102, "云南": 2530102, "海南": 2460105, "新疆": 2650102, "内蒙古": 2150102, "西藏": 2540102, "广西": 2450102, "宁夏": 2640104, "香港": "8a8142c3324990c7013266ba4f9e5030" },
      city: [],
      hot: { "北京": 3110101, "上海": 3310101, "天津": 3120101, "广东": 2440103, "江苏": 2320102, "浙江": 2330102 }
    };
  },
  methods: {
    handleProv(key, code) {
      this.address.provinceName = key;
      this.address.provinceCode = code;
      this.address.cityName = '';
      this.address.cityCode = '';
      this.city = area.city;
      this.status = 3;
          /**
           * 无法调通
           */
      // baseDataApi.getCity({ id: code }).then(res => {
      //   if (res.code === 'success') {
      //     this.city = res.data;
      //     this.status = 3;
      //   }
      // });
    },
    handleCity(obj) {
      this.address.cityName = obj.name;
      this.address.cityCode = obj.id;
      this.$emit('getData', this.address)
      // this.$emit('update:apple', this.address.provinceName + '-' + this.address.cityName);
      // this.$emit('close');
    }
  }
}
</script>
<style lang='less' scoped>
.input-select-content {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #d5d5d6;
  padding-bottom: 10px;
  .select-nav {
    width: 100%;
    height: 30px;
    overflow: hidden;
    display: flex;
    li {
      float: left;
      flex-grow: 1;
      box-sizing: border-box;
      width: 33.33% !important;
      margin: 0 !important;
      height: 100%;
      text-align: center;
      border-bottom: 1px solid #d5d5d6;
      border-right: 1px solid #d5d5d6;
      cursor: pointer;
    }
    li.no-border-right {
      border-right: 0;
    }

    li.active {
      border-bottom: 0;
    }
  }

  .select-content {
    padding: 15px;
    .select-content-item {
      overflow: hidden;
      li {
        float: left;
        height: 30px;
        width: 25%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 6px;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        &:active,
        &:hover {
          color: #fff;
          background-color: #47086c;
        }
      }
      .active {
        color: #fff;
        background-color: #47086c;
      }
    }
  }
}
</style>