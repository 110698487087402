export default {
    province: [{
        code: "3110101",
        leafRegions: [],
        name: "北京",
        isCharteredCities: true
    }, {
        code: "3310101",
        leafRegions: [],
        name: "上海",
        isCharteredCities: true
    }, {
        code: "3120101",
        leafRegions: [],
        name: "天津",
        isCharteredCities: true
    }, {
        code: "3500101",
        leafRegions: [],
        name: "重庆",
        isCharteredCities: true
    }, {
        code: "2130102",
        leafRegions: [],
        name: "河北省"
    }, {
        code: "2140121",
        leafRegions: [],
        name: "山西省"
    }, {
        code: "2150102",
        leafRegions: [],
        name: "内蒙古自治区"
    }, {
        code: "2210102",
        leafRegions: [],
        name: "辽宁省"
    }, {
        code: "2220102",
        leafRegions: [],
        name: "吉林省"
    }, {
        code: "2230102",
        leafRegions: [],
        name: "黑龙江省"
    }, {
        code: "2320102",
        leafRegions: [],
        name: "江苏省"
    }, {
        code: "2330102",
        leafRegions: [],
        name: "浙江省"
    }, {
        code: "2340102",
        leafRegions: [],
        name: "安徽省"
    }, {
        code: "2350102",
        leafRegions: [],
        name: "福建省"
    }, {
        code: "2360203",
        leafRegions: [],
        name: "江西省"
    }, {
        code: "2370102",
        leafRegions: [],
        name: "山东省"
    }, {
        code: "2410102",
        leafRegions: [],
        name: "河南省"
    }, {
        code: "2420102",
        leafRegions: [],
        name: "湖北省"
    }, {
        code: "2430482",
        leafRegions: [],
        name: "湖南省"
    }, {
        code: "2440103",
        leafRegions: [],
        name: "广东省"
    }, {
        code: "2450102",
        leafRegions: [],
        name: "广西壮族自治区"
    }, {
        code: "2460105",
        leafRegions: [],
        name: "海南省"
    }, {
        code: "2510104",
        leafRegions: [],
        name: "四川省"
    }, {
        code: "2520102",
        leafRegions: [],
        name: "贵州省"
    }, {
        code: "2530102",
        leafRegions: [],
        name: "云南省"
    }, {
        code: "2540102",
        leafRegions: [],
        name: "西藏自治区"
    }, {
        code: "2610102",
        leafRegions: [],
        name: "陕西省"
    }, {
        code: "2620421",
        leafRegions: [],
        name: "甘肃省"
    }, {
        code: "2630102",
        leafRegions: [],
        name: "青海省"
    }, {
        code: "2640104",
        leafRegions: [],
        name: "宁夏回族自治区"
    }, {
        code: "2650102",
        leafRegions: [],
        name: "新疆维吾尔自治区"
    }],
    hot: [{
            code: "3110101",
            leafRegions: [],
            name: "北京",
            isCharteredCities: true
        }, {
            code: "3310101",
            leafRegions: [],
            name: "上海",
            isCharteredCities: true
        }, {
            code: "3120101",
            leafRegions: [],
            name: "天津",
            isCharteredCities: true
        },
        {
            code: "2320102",
            leafRegions: [],
            name: "江苏省"
        }, {
            code: "2330102",
            leafRegions: [],
            name: "浙江省"
        },
        {
            code: "2440103",
            leafRegions: [],
            name: "广东省"
        }
    ],
    city: [{
        leafRegions: [],
        code: "610100",
        name: "西安市"
    }, {
        leafRegions: [],
        code: "610200",
        name: "铜川市"
    }, {
        leafRegions: [],
        code: "610300",
        name: "宝鸡市"
    }, {
        leafRegions: [],
        code: "610400",
        name: "咸阳市"
    }, {
        leafRegions: [],
        code: "610500",
        name: "渭南市"
    }, {
        leafRegions: [],
        code: "610600",
        name: "延安市"
    }, {
        leafRegions: [],
        code: "610700",
        name: "汉中市"
    }, {
        leafRegions: [],
        code: "610800",
        name: "榆林市"
    }, {
        leafRegions: [],
        code: "610900",
        name: "安康市"
    }, {
        leafRegions: [],
        code: "611000",
        name: "商洛市"
    }]
}